import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// model imports
import {
  CompanyLists,
  IUser,
  IUserCompany,
  MatchedCompanyInfo,
} from 'company-finder-common';

// service imports
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import { ServiceBase } from '../../../_common/services/_service.base';
import { Logger } from '../../utilities/logger/logger';
import { AuthnService } from '../authn/authn.service';
import { WebAnalyticsService } from '../web-analytics/web.analytics';

interface DeferredPromiseHandler<T> {
  promise: Promise<T>;
  resolve: (value?: IUser | PromiseLike<IUser>) => void;
  reject: (reason?: unknown) => void;
}

@Injectable()
export class UserService extends ServiceBase {
  // private properties
  private requestsForUser: DeferredPromiseHandler<IUser>[] = [];
  protected _logger = new Logger(this.constructor.name);
  private static getUserErrored = false;
  private singleJuniverseAuthAttempted = false;

  constructor(
    protected _httpClient: HttpClient,
    protected _context: DeploymentContext,
    protected authnService: AuthnService,
    protected _webAnalyticsService: WebAnalyticsService
  ) {
    super(_httpClient, _context, '/user');
  }

  // Keep a local cache of the last fetched/updated user settings
  private currentCachedUser: IUser;

  // public methods
  public async get(): Promise<IUser> {
    // NOTE: We used to provide an option to explicitly request the user's information be updated from LDAP.
    //       but that was complicated by the caching mechanism in place, and wasn't buying us much anyway,
    //       so as of ADJQ-838 that option has been removed.
    try {
      if (UserService.getUserErrored) {
        return null;
      }

      const user = await this._httpClient
        .get<IUser>(this._apiUrl, { headers: this._standardHeaders })
        .toPromise();

      this.currentCachedUser = user;
    } catch (ex) {
      UserService.getUserErrored = true;
      this._logger.warn(`Error loading user to update cache. ${ex}`);
      throw ex;
    }

    return this.currentCachedUser;
  }

  public clearCachedUser(): void {
    this.currentCachedUser = null;
  }

  /**
   * This will only ever make 1 attempt at Juniverse Auth
   */
  public async singleAttemptAtJuniverseAuth(): Promise<boolean> {
    // If we don't short circuit, user without Juniverse creds would trigger this at high frequency.
    // This relies on UserService being a singleton Angular Provider.
    if (!this.singleJuniverseAuthAttempted) {
      // To be extra safe, set this before the async call to ensure we don't end up with multiple concurrent async requests
      this.singleJuniverseAuthAttempted = true;
      // If we are in Juniverse, we need to call to get a JWT instead of the cookie-based authentication.
      return await this.authnService.attemptJuniverseAuth(
        this._webAnalyticsService,
        this._context.autoSendBypass,
        this._context.tokenToSend
      );
    }
  }

  public async getCachedUser(): Promise<IUser> {
    // Fetch if not cached yet.
    // TODO: Put in cache aging to expire cached value after something like a minute
    if (!this.authnService.isAuthenticated) {
      return null;
    }

    if (this.currentCachedUser == null) {
      // Only send the first request, queue up the others as deferred objects we'll resolve when the first request resolves.
      const sendRequest = this.requestsForUser?.length === 0;

      const deferred: DeferredPromiseHandler<IUser> = {
        promise: null,
        resolve: null,
        reject: null,
      };
      deferred.promise = new Promise<IUser>((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
      });

      this.requestsForUser.push(deferred);

      if (sendRequest) {
        // Wait for the actual API request.
        await this.get();
        // Now that we have a user, resolve the deferred objects.
        while (this.requestsForUser.length > 0) {
          const dph = this.requestsForUser.shift();
          dph.resolve(this.currentCachedUser);
        }
      } else {
        // Calls after the first get deferred, and will wait for their promises to get resolved.
        return deferred.promise;
      }
    }

    return this.currentCachedUser;
  }

  // Assuming we are properly using the user resolver, this will be populated already
  // and we can avoid an async setting up components. Without being behind the resolver,
  // there is no gaurentee that the get user call has run and these could be null
  public getCachedUserSync(): IUser {
    return this.currentCachedUser;
  }

  public getCompanyListsSync(): CompanyLists {
    const list = this.getCachedUserSync()?.companyLists;
    return new CompanyLists(
      list?.isCompanyContactFor,
      list?.isSiteHeadFor,
      list?.isCoOwnerFor
    );
  }

  public async getCompanyLists(): Promise<CompanyLists> {
    const list = (await this.getCachedUser())?.companyLists;
    return new CompanyLists(
      list?.isCompanyContactFor,
      list?.isSiteHeadFor,
      list?.isCoOwnerFor
    );
  }

  public get isReviewer(): boolean {
    return (
      this.getCompanyListsSync()?.isSiteHeadFor?.length > 0 ||
      this.getCompanyListsSync()?.isCoOwnerFor?.length > 0
    );
  }

  public get companiesForReview(): string[] {
    return [
      // Avoid duplicating companies in this list, for a lighter weight server request
      ...new Set([
        ...(this.getCompanyListsSync()?.isSiteHeadFor ?? []),
        ...(this.getCompanyListsSync()?.isCoOwnerFor ?? []),
      ]),
    ];
  }

  public get companiesForUpdate(): string[] {
    return this.getCompanyListsSync()?.isCompanyContactFor ?? [];
  }

  public get isCompanyContact(): boolean {
    return this.getCompanyListsSync()?.isCompanyContactFor?.length > 0;
  }

  public get hasCompaniesForUpdate(): boolean {
    return this.isReviewer || this.isCompanyContact;
  }

  public async save(user: IUser): Promise<IUser> {
    const result = await this._httpClient
      .post<IUser>(this._apiUrl, JSON.stringify(user), {
        headers: this._standardHeaders,
      })
      .toPromise();

    if (!this.currentCachedUser) {
      return result;
    }
    // If we have read the User from db at least once, update any fields that are being updated
    if (result.companiesFollowed != null) {
      this.currentCachedUser.companiesFollowed = result.companiesFollowed;
    }
    if (result.sectorsFollowed != null) {
      this.currentCachedUser.sectorsFollowed = result.sectorsFollowed;
    }
    if (result.locationsFollowed != null) {
      this.currentCachedUser.locationsFollowed = result.locationsFollowed;
    }
    if (result.userSearches != null) {
      // Assume all searches are being updated at once.
      // If a single search is updated, it will be through a different method
      this.currentCachedUser.userSearches = result.userSearches;
    }
    if (result.followCompaniesImResponsibleFor != null) {
      this.currentCachedUser.followCompaniesImResponsibleFor =
        result.followCompaniesImResponsibleFor;
    }
    return result;
  }

  public async saveCompaniesFollowed(
    companiesFollowed: IUserCompany[]
  ): Promise<IUser> {
    const user = await this.getCachedUser();
    const newUser: IUser = this.cloneBaseUser(user);
    newUser.companiesFollowed = companiesFollowed;

    return this.save(newUser);
  }

  public async isFollowingCompany(companyId: string): Promise<boolean> {
    const user = await this.getCachedUser();
    return (
      user?.companiesFollowed?.find(
        (userCompany) => userCompany.companyId === companyId
      ) != null
    );
  }

  public async setFollowingCompany(
    companyId: string,
    shouldBeFollowing: boolean
  ): Promise<void> {
    const user = await this.getCachedUser();
    const companiesFollowed = user?.companiesFollowed || [];
    const companyPos = companiesFollowed.findIndex(
      (userCompany) => userCompany.companyId === companyId
    );
    const isFollowing = companyPos >= 0;

    if (isFollowing !== shouldBeFollowing) {
      if (shouldBeFollowing) {
        companiesFollowed.push({ companyId: companyId });
      } else {
        companiesFollowed.splice(companyPos, 1);
      }
      this.saveCompaniesFollowed(companiesFollowed);
    }
  }

  private cloneBaseUser(user: IUser): IUser {
    if (!user) {
      return null;
    }

    return {
      updatedDate: new Date(),
      ...user,
    };
  }

  public async getMatchedCompanies(): Promise<MatchedCompanyInfo[]> {
    return this._httpClient
      .get<MatchedCompanyInfo[]>(`${this._apiUrl}/matchedCompanies`, {
        headers: this._standardHeaders,
      })
      .toPromise();
  }

  public async getMatchedCompaniesForWizard(
    user: IUser
  ): Promise<MatchedCompanyInfo[]> {
    return this._httpClient
      .post<MatchedCompanyInfo[]>(
        `${this._apiUrl}/matchedCompanies/wizard`,
        JSON.stringify(user),
        {
          headers: this._standardHeaders,
        }
      )
      .toPromise();
  }
}
