import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

// model imports
import {
  Company,
  CompanyResolutionType,
  Filter,
  ICompanyUpdate,
  AggregateStats,
  UpdateNotification,
  ReviewNotification,
  TagWithCount,
  Redirect,
  TagWithCountAndCanonicalString,
  urlIdentifierForCompany,
  SearchBody,
  SearchResult,
} from 'company-finder-common';

// service imports
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import { ServiceBase } from '../../../_common/services/_service.base';
import _ from 'lodash';

@Injectable()
export class CompanyService extends ServiceBase {
  // public properties
  public currentSearchResults: Company[] = null;
  private searchUrl: string;
  private aggregateSearchUrl: string;

  constructor(
    protected _httpClient: HttpClient,
    protected _context: DeploymentContext,
    private _router: Router
  ) {
    super(_httpClient, _context, '/company');
    this.searchUrl = _context.buildApiUrl('/search/company');
    this.aggregateSearchUrl = _context.buildApiUrl('/search/company/aggregate');
  }

  // public methods
  public async getById(id: string): Promise<Company> {
    const apiUrl = `${this._apiUrl}/${id}`;

    const result = await this._httpClient
      .get<Company>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public async getByEncodedNameOrOppId(
    encodedName: string,
    companyResolutionType: CompanyResolutionType = CompanyResolutionType.Details
  ): Promise<Company> {
    const apiUrl = `${this._apiUrl}/by-name-or-opp-id/${encodedName}/${companyResolutionType}`;

    const result = await this._httpClient
      .get<Company | Redirect>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    if ((result as Redirect).redirectTo !== undefined) {
      this._router.navigate([`/company/${(result as Redirect).redirectTo}`]);
    }

    return result as Company;
  }

  public async getByNameOrOppId(
    name: string,
    companyResolutionType: CompanyResolutionType = CompanyResolutionType.Details
  ): Promise<Company> {
    return this.getByEncodedNameOrOppId(urlIdentifierForCompany(name), companyResolutionType);
  }

  public async getTagCounts(): Promise<TagWithCount[]> {
    const apiUrl = `${this._apiUrl}/tags/count`;

    const result = await this._httpClient
      .get<TagWithCount[]>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public async getTagsWithCountsAndCanonical(): Promise<
    TagWithCountAndCanonicalString[]
  > {
    const allTags = await this.getTagCounts();
    return _.orderBy(
      allTags.map((tagWithCount) => ({
        tag: tagWithCount.tag,
        count: tagWithCount.count,
        canonicalString: tagWithCount.tag.toLowerCase(),
      })),
      'canonicalString'
    );
  }

  public async getTags(): Promise<string[]> {
    const apiUrl = `${this._apiUrl}/tags/list`;

    const result = await this._httpClient
      .get<string[]>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public async searchAndFilter(
    predicate: string,
    from: number,
    size: number,
    filter: Filter,
    by: string = null
  ): Promise<SearchResult> {
    const body = new SearchBody(predicate, filter, from, size, by);

    const result = await this._httpClient
      .post<SearchResult>(this.searchUrl, body, {
        headers: this._standardHeaders,
      })
      .toPromise();

    this.currentSearchResults = result?.companies ?? [];

    return (
      result ?? {
        companies: [],
        totalCompaniesCount: 0,
      }
    );
  }

  public async searchAndFilterAggregate(
    predicate: string,
    from: number,
    size: number,
    filter: Filter
  ): Promise<AggregateStats> {

    if (
      predicate?.trim() === '' &&
      from === 0 &&
      size === 0 &&
      filter === Filter.emptyFilter
    ) {
      return this._context.comprehensiveResults;
    }

    return this._httpClient
      .post<AggregateStats>(this.aggregateSearchUrl, new SearchBody(predicate, filter, from, size), {
        headers: this._standardHeaders,
      })
      .toPromise();
  }

  public async companiesForExport(
    predicate: string,
    filter: Filter
  ): Promise<ArrayBuffer> {
    const apiUrl = `${this._apiUrl}/export`;

    const requestBody = new SearchBody(predicate, filter);

    return await this._httpClient
      .post(apiUrl, requestBody, {
        headers: this._standardHeaders,
        responseType: 'arraybuffer',
      })
      .toPromise();
  }

  public async getWordData(company: Company): Promise<ArrayBuffer> {
    const apiUrl = `${this._apiUrl}/generate/word`;

    return await this._httpClient
      .post(apiUrl, { company: company }, { responseType: 'arraybuffer' })
      .toPromise();
  }

  public async getPdfData(company: Company): Promise<ArrayBuffer> {
    const apiUrl = `${this._apiUrl}/generate/pdf`;

    return await this._httpClient
      .post(apiUrl, { company: company }, { responseType: 'arraybuffer' })
      .toPromise();
  }

  public async getWordDataForSearch(
    predicate: string,
    filter: Filter
  ): Promise<ArrayBuffer> {
    const apiUrl = `${this._apiUrl}/generate/word/search`;

    return await this._httpClient
      .post(
        apiUrl,
        { predicate: predicate, filter: filter },
        { responseType: 'arraybuffer' }
      )
      .toPromise();
  }

  public async getPdfDataForSearch(
    predicate: string,
    filter: Filter,
    from: number,
    size: number
  ): Promise<ArrayBuffer> {
    const apiUrl = `${this._apiUrl}/generate/pdf/search`;

    return await this._httpClient
      .post(
        apiUrl,
        { predicate: predicate, filter: filter, from, size },
        { responseType: 'arraybuffer' }
      )
      .toPromise();
  }

  public async reviewCompanyUpdate(
    companyUpdate: ICompanyUpdate
  ): Promise<ICompanyUpdate> {
    const apiUrl = `${this._apiUrl}/review`;

    const body = {
      companyUpdate: companyUpdate,
    };
    const result = await this._httpClient
      .post<ICompanyUpdate>(apiUrl, body, {
        headers: this._standardHeaders,
      })
      .toPromise();

    return result;
  }

  public async updateCompany(
    companyUpdate: ICompanyUpdate
  ): Promise<ICompanyUpdate> {
    const apiUrl = `${this._apiUrl}/update`;

    const body = {
      companyUpdate: companyUpdate,
    };
    const result = await this._httpClient
      .post<ICompanyUpdate>(apiUrl, body, {
        headers: this._standardHeaders,
      })
      .toPromise();

    return result;
  }

  public async deletePendingUpdateByOpportunityId(
    opportunityId: string
  ): Promise<unknown> {
    const apiUrl = `${this._apiUrl}/update/by-opportunity-id/${opportunityId}`;

    const result = await this._httpClient
      .delete<unknown>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public async deletePendingAddedDealOrFundingUpdateByModelId(
    opportunityId: string,
    type: string,
    modelId: string,
    deleteCompanyUpdate: boolean
  ): Promise<unknown> {
    const apiUrl = `${this._apiUrl}/update/${opportunityId}/${type}/${modelId}/${deleteCompanyUpdate}`;

    const result = await this._httpClient
      .delete<unknown>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public async getApprovedUnprocessedUpdatesByOpportunityId(
    opportunityId: string
  ): Promise<ICompanyUpdate[]> {
    const apiUrl = `${this._apiUrl}/update/approved/unprocessed/by-opportunity-id/${opportunityId}`;

    const result = await this._httpClient
      .get<ICompanyUpdate[]>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public async getSavedOrPendingUpdateByOpportunityId(
    opportunityId: string
  ): Promise<ICompanyUpdate> {
    const apiUrl = `${this._apiUrl}/update/pending/by-opportunity-id/${opportunityId}`;

    const result = await this._httpClient
      .get<ICompanyUpdate>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public async notification(companyId: string): Promise<UpdateNotification> {
    const apiUrl = `${this._apiUrl}/notification/${companyId}`;

    const result = await this._httpClient
      .get<UpdateNotification>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public async reviews(companyNames: string[]): Promise<ReviewNotification[]> {
    const apiUrl = `${this._apiUrl}/reviews/count`;

    const result = await this._httpClient
      .post<ReviewNotification[]>(
        apiUrl,
        { companyNames: companyNames },
        { headers: this._standardHeaders }
      )
      .toPromise();

    return result;
  }
}

