import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';

import { ComponentBase } from '../../../../_common/components/_component.base';

// model imports
import { Company, SortType } from 'company-finder-common';

// service imports
import { SearchService } from '../../../../_common/services/search/search.service';
import { WebAnalyticsService } from '../../../../_common/services/web-analytics/web.analytics';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'sort-control',
  templateUrl: './sort-control.component.html',
  styleUrls: ['./sort-control.component.scss'],
})
export class SortControlComponent
  extends ComponentBase
  implements OnDestroy, OnInit
{
  // public properties
  @Input()
  public companies: Company[];
  public sortResultsSubject: Subject<Company[]> = new Subject<Company[]>();
  public showMenu = false;
  private readonly SortTypes = Object.values(SortType);
  private readonly SortTypesWithoutRanking = this.SortTypes.filter(
    (value) => value !== SortType.Ranking
  );

  public constructor(
    dc: DeploymentContext,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
  }

  // public methods
  public get sortTypes(): string[] {
    return this._searchService.currentSearchPredicate
      ? this.SortTypes
      : this.SortTypesWithoutRanking;
  }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = undefined;
    }
  }

  public get sortType(): SortType {
    return this._searchService.filter?.sort ?? SortType.Ranking;
  }

  @HostListener('window:click', ['$event'])
  protected onClick(_event: Event): void {
    this.showMenu = false;
  }

  public setShowMenu(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    this.showMenu = true;
  }

  public async sort(sortType: SortType): Promise<void> {
    switch (sortType) {
      case SortType.Date:
        this._searchService.filter.sort = SortType.Date;
        break;
      case SortType.Alpha:
        this._searchService.filter.sort = SortType.Alpha;
        break;
      case SortType.Ranking:
        this._searchService.filter.sort = SortType.Ranking;
        break;
    }

    this._searchService.drilldownSubject.next(this._searchService.filter);

    this._webAnalyticsService.trackEvent('sort-search-results', {
      label: sortType,
      // FUTURE: This feels a bit fragile, but if we don't have a count yet from a previous search,
      // assume we are working off the full set
      value:
        this._searchService.companyCountFromLastSearch ||
        this._deploymentContext.comprehensiveSummary.numberOfCompanies,
    });
  }
}
