import { Component, Input } from '@angular/core';

import { ComponentBase } from '../../../../_common/components/_component.base';

// model imports
import { LocalizedTextIds } from 'company-finder-common';

// utility imports
import { WebAnalyticsService } from '../../../../_common/services/web-analytics/web.analytics';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { Summary } from '../../../../_common/utilities/summary/summary';

@Component({
  selector: 'strategic-collaboration',
  templateUrl: './strategic-collaboration.component.html',
  styleUrls: ['./strategic-collaboration.component.scss'],
})
export class StrategicCollaborationComponent extends ComponentBase {
  // public properties
  public isShowingTooltip = {
    publiclyOffered: false,
    strategicRelationships: false,
  };
  @Input()
  public summary: Summary;
  public title = this.Loc(LocalizedTextIds.StrategicCollaborationTitle);

  public constructor(
    dc: DeploymentContext,
  ) {
    super(dc);
  }

  // public methods
  public showTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = true;
  }

  public hideTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = false;
  }

  public getSumSplit(formattedCurrency: string, index: number): string {
    const items = formattedCurrency?.split(' ') ?? [];
    return index >= 0 && index < items.length
      ? items[index]
      : formattedCurrency;
  }
}
