import { NameValuePair, SectorCounts } from './PrimarySectorCount';
import { StatusWithCount } from './objectWithCount';

export class SharedStats {
    public numberOfCompanies: number = 0;
    public numberOfPubliclyOfferedCompanies: number = 0;
    public numberOfAcquiredCompanies: number = 0;
    public numberOfCrossSectorCompanies: number = 0;
    public numberOfNewCompanies: number = 0;
    public numberOfBlueKnights: number = 0;
    public numberOfQfcWinners: number = 0;
    public numberOfNACompanies: number = 0;

    public locationStatusCounts = {}; // FUTURE Better typing
    public sumTotalSecuredAndContingentAmount: number = 0;
    public numberOfResidentsForStatusTile: number = 0;
    public sectorGroups: SectorCounts[] = [];
    public allStatusesWithCounts: StatusWithCount[] = [];

    public serialEntrepreneurPercentage: number = 0;
    public femaleLedPercentage: number = 0;
    public firstTimeEntrepreneurPercentage: number = 0;
    public minorityLedPercentage: number = 0;
    public totalUniqueCompaniesWithDeals = 0;
    public tagCounts: NameValuePair[] = [];
}
