import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ComponentBase } from '../../../../_common/components/_component.base';

// model imports
import { Company, LocalizedTextIds, LocPrefix } from 'company-finder-common';

// utility imports
import { ApplicationContext } from '../../../../_common/utilities/application-context/application-context';
import { VisualizationType } from '../../../../_common/constants/VisualizationType';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { WebAnalyticsService } from '../../../../_common/services/web-analytics/web.analytics';
import { LocationIndicatorComponent } from './components/location-indicator/location-indicator.component';

import _ from 'lodash';

import {
  LocationGroup,
  LocationInfo,
} from '../../../../_common/models/location-group';
import { Summary } from '../../../../_common/utilities/summary/summary';
import { Location } from 'company-finder-common';
import { TooltipHandler } from './models/tooltip-handler';

@Component({
  selector: 'explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent
  extends ComponentBase
  implements OnChanges, OnInit {
  // public properties
  public static readonly SMALL_INDICATOR_THRESHOLD: number = 1 / 3;
  public static readonly MEDIUM_INDICATOR_THRESHOLD: number = 2 / 3;
  public locationInfos = new LocationGroup();
  public virtualLocationInfo: LocationGroup;
  @Input()
  public summary: Summary;
  public title = this.Loc(LocalizedTextIds.ExploreCompanies);
  public VisualizationTypes = VisualizationType;
  public visualizationType: VisualizationType;

  public get maxCount(): number {
    return this.summary.maxCompanyCount;
  }
  public tooltipHandler: TooltipHandler;

  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);

    this.visualizationType =
      this._applicationContext.activeVisualizationType ||
      (this.featureSwitches.enableShowGeoLocationMap
        ? VisualizationType.Geography
        : this.featureSwitches.enableSectors
          ? VisualizationType.Sector
          : this.featureSwitches.enableShowTags
            ? VisualizationType.Tags
            : null);

    this.tooltipHandler = {
      locationIndicator: null,
      left: 0,
      bottom: 0,
      markerElement: null,
      show: (locationIndicator: LocationIndicatorComponent) => {
        this.registerWindowHandlerSubscription('click', (_ev) =>
          this.tooltipHandler.hide()
        );
        this.tooltipHandler.locationIndicator = locationIndicator;
      },
      hide: () => {
        this.tooltipHandler.locationIndicator = undefined;
        this.unregisterWindowHandlerSubscription('click');
      },
      reposition: () => {
        const locIndicator = this.tooltipHandler.locationIndicator;
        if (locIndicator) {
          locIndicator.positionTooltip();
        }
      },
    };
  }

  // public getters
  public get isSector(): boolean {
    return this.visualizationType === VisualizationType.Sector;
  }

  public get isTags(): boolean {
    return this.visualizationType === VisualizationType.Tags;
  }

  public get isGeography(): boolean {
    return this.visualizationType === VisualizationType.Geography;
  }

  public get tooltipStyle(): Partial<CSSStyleDeclaration> {
    return {
      left: `${this.tooltipHandler.left}px`,
      bottom: `${this.tooltipHandler.bottom}px`,
    };
  }

  // public methods
  public async ngOnChanges(): Promise<void> {
    if (!this.summary) {
      return;
    }

    // Reset the markers prior to calculations
    this.locationInfos = new LocationGroup();
    this.virtualLocationInfo = new LocationGroup();

    this.summary.locations
      .map((location) => this.getLocationInfo(location))
      .forEach((info) => {
        if (!info.location.excludeFromMap) {
          this.locationInfos.push(info);
        } else {
          this.virtualLocationInfo.push(info);
        }
      });
  }

  public ngOnInit(): void {
    // Note: When entering this component from a different route, ngOnChanges is called with an undefined Summary
    //  then ngOnInit, and then ngOnChanges is called as a result of having a Summary object provided from a service call.
    //  When toggling between Explore and Search Results, going back to Explore triggers ngOnChanges WITH Summary
    //  data prior to the ngOnInit call, and then not again.  So, don't attempt to initialize variables here that might
    //  otherwise step over the previous data manipultion that might have already been done in ngOnChanges.
  }

  public sectorShortName(sector: string): string {
    return this.LocWithPrefix(sector, LocPrefix.SectorShortName);
  }

  private get sectorsAreConfigured(): boolean {
    return this._deploymentContext.sectors.length > 0;
  }

  private getLocationInfo(location: Location): LocationInfo {
    const sectors = this.sectorsAreConfigured
      ? this._deploymentContext.sectors
      : [null]; // If no sectors, group all into one "sector" with no name

    const sectorCounts = sectors.map(
      (sector) =>
        this.summary.numberOfCompaniesAtLocationBySector(
          location?.name,
          sector
        ) || 0
    );
    return {
      location,
      sectorCounts: this.sectorsAreConfigured
        ? sectorCounts
        : [_.sum(sectorCounts)],
    };
  }

  public setVisualizationType(visualizationType: VisualizationType): void {
    this.visualizationType = this._applicationContext.activeVisualizationType =
      visualizationType;
    this._webAnalyticsService.trackEvent('select-visualization', {
      label: this.visualizationType,
    });
  }
}
