import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ComponentBase } from '../../_common/components/_component.base';

// model imports
import { Company, Filter, LocalizedTextIds } from 'company-finder-common';

// service/utility imports
import { CompanyService } from '../../_common/services/company/company.service';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { Summary } from '../../_common/utilities/summary/summary';
import {
  MenuOption,
  getSelectedItems,
} from '../../_common/utilities/menu-options/menu-option.util';
import Swiper from 'swiper';

@Component({
  selector: 'by-the-numbers',
  templateUrl: './by-the-numbers.component.html',
  styleUrls: ['./by-the-numbers.component.scss'],
})
export class ByTheNumbersComponent
  extends ComponentBase
  implements OnInit, AfterViewInit
{
  // public properties
  public activeFilterType: string;
  public dropdownValue: string;
  public filter: Filter = Filter.emptyFilter;
  public menuItems = {
    acrossJlabs: this.Loc(LocalizedTextIds.AcrossApp),
    acrossSector: this.Loc(LocalizedTextIds.AcrossaSector),
    acrossLocations: this.Loc(LocalizedTextIds.AcrossLocations),
    accrossInitiatives: this.Loc(LocalizedTextIds.AcrossInitiatives),
  };
  public readonly blueKnight = this.Loc(LocalizedTextIds.BlueKnight);
  public readonly quickfire = this.Loc(LocalizedTextIds.QuickfireChallenge);
  public locationOptions: MenuOption[] = [];
  public sectorOptions: MenuOption[] = [];
  public initiativeOptions: MenuOption[] = [
    { db: this.blueKnight, name: this.blueKnight, value: false },
    { db: this.quickfire, name: this.quickfire, value: false },
  ];
  public showMenu = {
    main: false,
    location: false,
    sector: false,
    initiative: false,
  };
  public summary: Summary;
  public title = this.Loc(LocalizedTextIds.CompaniesbytheNumbers);
  public swiperConfig = {
    injectStyles: [
      `:host .swiper-pagination { position: relative; bottom: 0px; }`,
    ],
  };

  // private properties
  private _uniqueLocations: string[];

  public constructor(
    dc: DeploymentContext,
    private _companyService: CompanyService
  ) {
    super(dc);

    this.dropdownValue = this.menuItems.acrossJlabs;
    this.activeFilterType = this.menuItems.acrossJlabs;
  }

  public ngAfterViewInit(): void {
    const swiper = document.querySelector('.bottom-swiper') as {
      swiper?: Swiper;
    };
    // The DOM may have changed (specifically removing tiles)
    // This could cause issues, especially around pagination,
    // so get the swiper to update.
    swiper?.swiper?.update();
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    this.summary = this._deploymentContext.comprehensiveSummary;
    this.initMenus();
  }

  public hideMenus(ev: MouseEvent, propagate?: boolean): void {
    if (!propagate) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.showMenu = {
      main: false,
      location: false,
      sector: false,
      initiative: false,
    };
  }

  @HostListener('window:click', ['$event'])
  public onClick(event: MouseEvent): void {
    this.hideMenus(event, true);
  }

  public setShowMenu(ev: MouseEvent, menuId: string): void {
    ev.preventDefault();
    ev.stopPropagation();

    const isShowing = this.showMenu[menuId];
    Object.keys(this.showMenu).forEach((key) => {
      if (key !== 'main' && key !== menuId) {
        this.hideMenu(key);
      }
    });
    this.showMenu[menuId] = !isShowing;
  }

  public stopPropagation(ev: Event): void {
    ev.stopPropagation();
  }

  public async updateFilter(
    criteriaId: string,
    menuItem?: MenuOption
  ): Promise<void> {
    switch (criteriaId) {
      case 'location':
        this.filter.primarySectors = [];
        this.filter.locations = getSelectedItems(this.locationOptions);
        this.dropdownValue =
          this.filter.locations.length === 0 ||
          this.filter.locations.length === this.locationOptions.length
            ? this.Loc(LocalizedTextIds.ByTheNumbersLocationsAll)
            : this.LocPluralize(
                LocalizedTextIds.ByTheNumbersLocations,
                this.filter.locations.length,
                this.filter.locations.length
              );
        this.activeFilterType = this.menuItems.acrossLocations;
        break;
      case 'main':
        this.clearFilter();
        this.dropdownValue = this.menuItems.acrossJlabs;
        this.activeFilterType = this.menuItems.acrossJlabs;
        break;
      case 'sector':
        this.clearFilter();
        this.filter.primarySectors = [menuItem.db];
        this.dropdownValue = menuItem.db;
        this.activeFilterType = this.menuItems.acrossSector;
        break;
      case 'initiative':
        this.clearFilter();
        this.filter.isBlueKnight = menuItem.db === this.blueKnight;
        this.filter.isQFCWinner = menuItem.db === this.quickfire;
        this.dropdownValue = menuItem.db;
        this.activeFilterType = this.menuItems.accrossInitiatives;
        break;
    }

    await this.refreshData();
  }

  // private methods
  private clearFilter() {
    this.filter.clear();
    this.initLocations();
  }

  public get includesNorthAmericanLocations(): boolean {
    return this.summary.numberOfNACompanies > 0;
  }

  private hideMenu(menuId: string): void {
    this.showMenu[menuId] = false;
  }

  private initMenus(): void {
    this._uniqueLocations = this.summary.locations.map(
      (location) => location.name
    );
    this.filter = new Filter(
      this._uniqueLocations,
      [],
      [],
      [],
      [],
      this._uniqueLocations.length,
      0,
      0,
      0,
      0
    );

    this.initLocations();

    this.summary.sectors.forEach((sector) => {
      if (!this.sectorOptions.find((option) => option.db === sector)) {
        this.sectorOptions.push({
          db: sector,
          name: sector,
          value: false,
        });
      }
    });
  }

  private initLocations(): void {
    this.summary.locations.map((location) => {
      const existingOption = this.locationOptions.find(
        (opt) => opt.name === location.aggregateName
      );
      if (existingOption) {
        existingOption.aggregateDbValues.push(location.name);
        return;
      }

      this.locationOptions.push({
        db: location.name,
        name: location.aggregateName ?? location.name,
        value: false,
        aggregateDbValues: location.aggregateName ? [location.name] : null,
      });
    });

    this.locationOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  private async refreshData(): Promise<void> {
    const result = await this._companyService.searchAndFilterAggregate(
      '',
      0,
      0,
      this.filter
    );

    this.summary = new Summary(result);
  }
}
