import { Company } from '../model/company';
import { Opportunity } from '../model';
import { hasDealsWithJnJ, contractExecutedOpportunities } from '../utility';
import { CurrentRdStage, GeneralConfig } from '../config';
import { SearchResult } from './search-result';
import { SharedStats } from './sharedStats';

export class AggregateStats extends SharedStats {
    public constructor(
        searchResults: SearchResult = new SearchResult(),
        config: GeneralConfig,
        rdStagesBySector: { [sector: string]: CurrentRdStage[] }
    ) {
        super();
        const { sectors: allSectors, statusMetadata, statusForTile } = config;
        const companies = searchResults?.companies ?? [];
        this.numberOfCompanies = searchResults?.totalCompaniesCount;
        let serialEntrepreneurs = 0;
        let firstTimeEntrepreneurs = 0;
        let femaleLed = 0;
        let minorityLed = 0;

        // Initialize count collections prior to aggregation
        statusMetadata.metadata.forEach((metadata) => {
            metadata.values.forEach((metaValue) => {
                this.allStatusesWithCounts.push({
                    statusItem: {
                        status: metaValue.value,
                        displayName: metaValue.display,
                    },
                    count: 0,
                });
            });
        });

        const metaForTile = statusMetadata.getMetadata(statusForTile);

        companies.forEach((company) => {

            let matchesTileStatus = false;
            company.statuses.forEach((status) => {
                const statusWithCount = this.allStatusesWithCounts.find((s) => s.statusItem.status === status);
                statusWithCount.count++;

                matchesTileStatus = matchesTileStatus || (metaForTile?.statusType === 'company' &&
                    status === statusForTile)
            });
            // Set to true now if it is a company status. If it is a location status,
            // check that during the location loop. We increment the count after that
            matchesTileStatus = metaForTile?.statusType === 'company' &&
                company.statuses.some((status) => status === statusForTile);
            // TAGS 
            company.tags?.map((tag) => {
                // Tag comparisons should be case-insensitive.
                const existingItem = this.tagCounts.find(
                    (item) => item.name.toLowerCase() === tag.toLowerCase()
                );
                if (existingItem) {
                    existingItem.value = existingItem.value + 1;
                } else {
                    this.tagCounts.push({
                        name: tag,
                        value: 1,
                    });
                }
            });

            // LOCATION STATUS COUNTS
            let includesNALocation = false;
            company.locations.forEach((location) => {
                includesNALocation = includesNALocation || location.isNorthAmerican;

                location.locationStatuses.forEach((status) => {
                    const statusWithCount = this.allStatusesWithCounts.find((s) => s.statusItem.status === status);
                    statusWithCount.count++;
                    matchesTileStatus = matchesTileStatus || (metaForTile?.statusType === 'location' &&
                        status === statusForTile)
                });

                this.locationStatusCounts[location.name] = this.locationStatusCounts[location.name] ?? { count: 0 };
                this.locationStatusCounts[location.name].count++;
                if (!this.locationStatusCounts[location.name][company.primarySector]) {
                    this.locationStatusCounts[location.name][company.primarySector] = 1;
                } else {
                    this.locationStatusCounts[location.name][company.primarySector]++;
                }
            });

            if (matchesTileStatus) {
                this.numberOfResidentsForStatusTile++;
            }

            if (includesNALocation) {
                this.numberOfNACompanies++;

                // We only calculate minoriy led for NA companies for legal reasons
                if (company.minorityLed) {
                    minorityLed++;
                }
            }

            // DEAL COUNTS
            if (hasDealsWithJnJ(company, true)) {
                this.totalUniqueCompaniesWithDeals += 1;
            }

            const { sectorGroup, subsectorGroup } = this.getSectorGroups(company.primarySector, company.primarySubSector);

            sectorGroup.value++;
            subsectorGroup.value++;

            if (!!company.currentRdStage) {
                let stgCount = sectorGroup.stageCounts?.find(
                    (stageCount) => stageCount.stage === company.currentRdStage
                );

                if (!stgCount) {
                    stgCount = { stage: company.currentRdStage, count: 0 };
                    sectorGroup.stageCounts.push(stgCount);
                }

                stgCount.count++;
            }


            if (company.isQfcWinner) {
                sectorGroup.dealCount++;
                subsectorGroup.dealCount++;
                this.numberOfQfcWinners++;
            }

            contractExecutedOpportunities(company).forEach((opp: Opportunity) => {
                this.incrementSectorDealCounts(opp.primarySector, opp.primarySubSector);
            });

            company.limitedDealOpportunities?.forEach((item) => {
                this.incrementSectorDealCounts(item.primarySector, item.primarySubSector);
            });

            this.sumTotalSecuredAndContingentAmount += company.totalSecuredAndContingentAmount ?? 0;

            // Company Counts
            if (company.isPubliclyOffered) {
                this.numberOfPubliclyOfferedCompanies++;
            }

            if (company.secondarySector &&
                company.secondarySector !== company.primarySector) {
                this.numberOfCrossSectorCompanies++
            }

            if (company.isBlueKnight) {
                this.numberOfBlueKnights++;
            }

            if (company.wasAcquired) {
                this.numberOfAcquiredCompanies++;
            }

            if (this.isNewLastFullQuarter(company)) {
                this.numberOfNewCompanies++;
            }

            if (company.firstTimeEntrepreneur) {
                firstTimeEntrepreneurs++;
            } else {
                serialEntrepreneurs++;
            }

            if (company.womenLed) {
                femaleLed++;
            }

        });

        // Now that we have all the counts, tage, etc from the companies, calculate 
        // percentages and sort the data
        if (this.numberOfCompanies > 0) {
            this.serialEntrepreneurPercentage = serialEntrepreneurs / this.numberOfCompanies;
            this.firstTimeEntrepreneurPercentage = firstTimeEntrepreneurs / this.numberOfCompanies;
            this.femaleLedPercentage = femaleLed / this.numberOfCompanies;
        }

        if (this.numberOfNACompanies > 0) {
            this.minorityLedPercentage = minorityLed / this.numberOfNACompanies;
        }

        // Only use the sectors included but match the order
        this.sectorGroups = allSectors.filter((sector) => this.sectorGroups.some((grp) => grp.name === sector))
            .map((sector) => this.sectorGroups.find((grp) => grp.name === sector));

        // Respect the sort order of the defined stages
        this.sectorGroups.forEach((sectorGroup) => {
            sectorGroup.stageCounts = rdStagesBySector[sectorGroup.name].map((rdStages) => {
                const grp = sectorGroup.stageCounts.find((count) => count.stage === rdStages.value);
                return grp ?? { stage: rdStages.value, count: 0 }
            });
        });

        this.tagCounts = this.tagCounts.sort((a, b) => b.value - a.value);
    }

    private getSectorGroups(primarySector: string, primarySubSector: string) {
        let sectorGroup = this.sectorGroups.find((sectorGroup) => sectorGroup.name === primarySector)
        if (!sectorGroup) {
            sectorGroup = {
                name: primarySector, value: 0, dealCount: 0, children: [], stageCounts: []
            };

            if (primarySector?.length > 0) {
                // We won't need to keep this if there is no sector, but return the dummy
                // so the code flows along and we can stil use sectorGroup.value++
                this.sectorGroups.push(sectorGroup);
            }
        }

        let subsectorGroup = sectorGroup.children.find((subSectorGroup) => subSectorGroup.name === primarySubSector);
        if (!subsectorGroup) {
            subsectorGroup = {
                name: primarySubSector, value: 0, dealCount: 0
            };

            if (primarySubSector?.length > 0) {
                // We won't need to keep this if there is no sector, but return the dummy
                // so the code flows along and we can stil use subSectorGroup.value++
                sectorGroup.children.push(subsectorGroup);
            }
        }

        return { sectorGroup, subsectorGroup };
    }

    private incrementSectorDealCounts(primarySector: string, primarySubSector: string) {
        const { sectorGroup, subsectorGroup } = this.getSectorGroups(primarySector, primarySubSector);
        sectorGroup.dealCount++;
        subsectorGroup.dealCount++;
    }

    private isNewLastFullQuarter(company: Company): boolean {
        const today = new Date();
        const quarter = Math.floor(today.getMonth() / 3);
        const startCurrentFullQuarter = new Date(
            today.getFullYear(),
            quarter * 3,
            1
        );
        const startPreviousFullQuarter = new Date(
            today.getFullYear(),
            quarter * 3 - 3,
            1
        );
        const commencementDate = new Date(company.commencementDate);
        return (
            startPreviousFullQuarter <= commencementDate &&
            commencementDate < startCurrentFullQuarter
        );
    }
}
