import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import _ from 'lodash';

import { ComponentBase } from '../../_common/components/_component.base';

// model imports
import {
  IUser,
  Company,
  Filter,
  IUserSector,
  IUserSearch,
  TimeZoneUtils,
  asyncForEach,
  sectorToDisplayString,
  emailFrequencyToDisplayString,
  LocalizedTextIds,
} from 'company-finder-common';

// service/utility imports
import { ApplicationContext } from '../../_common/utilities/application-context/application-context';
import { LogoSize } from '../../_common/constants/LogoSize';
import {
  addCompaniesToUser,
  addLocationsToUser,
  addSectorsToUser,
  updateCountOfMatchedCompanies,
} from '../../_common/utilities/preferences/preferences.util';
import { CompanyService } from '../../_common/services/company/company.service';
import { UserService } from '../../_common/services/user/user.service';
import {
  LocationWithCountAndCanonicalString,
  SectorWithCountAndCanonicalString,
} from 'company-finder-common';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { SavedSearchModalComponent } from './components/saved-search-modal/saved-search-modal.component';
import { SavedSearchSummaryComponent } from './components/saved-search/saved-search-summary.component';
import { AuthnService } from '../../_common/services/authn/authn.service';

export enum WizardStep {
  Sectors,
  Companies,
  Locations,
}
@Component({
  selector: 'preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent extends ComponentBase implements OnInit {
  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _userService: UserService,
    private _companyService: CompanyService,
    private _authnService: AuthnService
  ) {
    super(dc);
  }

  public logoSize: LogoSize = LogoSize.Medium;
  public companiesDirectlyFollowed: Company[];
  public companiesImResponsibleFor: Company[];
  public locationsFollowed: string[] = [];
  public sectorsFollowed: IUserSector[] = [];
  public searchesFollowed: IUserSearch[] = [];
  public _user: IUser;
  public showCompaniesPickerModal = false;
  public showLocationsPickerModal = false;
  public showSectorsPickerModal = false;
  public showPreferencesModal = false;
  public companyCache = new Map<string, Company>();
  @ViewChild(SavedSearchModalComponent, { static: true })
  savedSearchModal: SavedSearchModalComponent;
  @ViewChildren(SavedSearchSummaryComponent)
  public savedSearchSummaryComponents!: QueryList<SavedSearchSummaryComponent>;
  public savedSearchToEdit: IUserSearch;

  public matchedLocationsCount = 0;
  public matchedSectorsCount = 0;
  public matchedSearchesCount = 0;
  public matchedBlueKnightCount = 0;
  public matchedTotal = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fieldOptions: any;

  public get isInternal(): boolean {
    return this._authnService.isInternal;
  }

  public get user(): IUser {
    return this._user;
  }

  public get userEmailFrequency(): string {
    return emailFrequencyToDisplayString(this.user.emailNewsletterFrequency);
  }

  public get companyProvidedUpdates(): boolean {
    return this.user.userNewsUpdatePreferences.emailUpdates;
  }

  public get inTheNewsUpdates(): boolean {
    return this.user.userNewsUpdatePreferences.emailNews;
  }

  public get newCompaniesUpdates(): boolean {
    return this.user.userNewsUpdatePreferences.emailNewCompanies;
  }

  public get countOfCompaniesForWhichYoullSeeUpdates(): number {
    return this.matchedTotal;
  }

  public get WizardStep(): typeof WizardStep {
    return WizardStep;
  }

  public isOnBoardingWizard: boolean;

  private isUserLoaded = false;

  public async ngOnInit(): Promise<void> {
    this._user = this._userService.getCachedUserSync();
    this.isOnBoardingWizard = !!history.state._showWizard;

    this._user.timeZoneOffsetInHours = TimeZoneUtils.getCurrentTimeZoneOffset(
      false,
      false
    ); // no half or special tzs

    // If we're following JPAL companies, start the fetch of that list now
    this.getCompaniesImResponsibleForIfNeeded();

    const idList = this.user.companiesFollowed.map(
      (company) => company.companyId
    );
    await this.cacheCompanies(idList);

    this.companiesDirectlyFollowed = idList.map((id) =>
      this.companyCache.get(id)
    );

    this.updateLocationsFollowed();
    this.user.locationsFollowed = this.locationsFollowed;

    this.updateSectorsFollowed();
    this.user.sectorsFollowed = this.sectorsFollowed;

    this.searchesFollowed = _.sortBy(
      this.user.userSearches || [],
      (userSearch) => userSearch.name
    );
    this.user.userSearches = this.searchesFollowed;

    this.isUserLoaded = true;

    await this.updateCountOfMatchedCompanies();

    if (this.isOnBoardingWizard) {
      if (this.featureSwitches.enableSectors) {
        this.showAddSectors();
      } else {
        this.showAddCompanies();
      }
    }
  }

  /** *****
   *  Preferences/Settings
   */
  public showPreferences(): void {
    this.showPreferencesModal = true;
  }

  public getFieldOptions(data: unknown): void {
    this.fieldOptions = data;
  }

  public closePreferences(): void {
    this.showPreferencesModal = false;
    this.isOnBoardingWizard = false;
  }

  /** *****
   *  Companies
   */
  public showAddCompanies(): void {
    this.showCompaniesPickerModal = true;
  }

  public get followBlueKnightCompanies(): boolean {
    return this.user.followBlueKnightCompanies;
  }

  public set followBlueKnightCompanies(follow: boolean) {
    this.user.followBlueKnightCompanies = follow;
    this.saveUser();
  }

  public get followCompaniesImResponsibleFor(): boolean {
    return this.user.followCompaniesImResponsibleFor;
  }

  public set followCompaniesImResponsibleFor(follow: boolean) {
    this.user.followCompaniesImResponsibleFor = follow;
    this.getCompaniesImResponsibleForIfNeeded();
    this.saveUser();
  }

  private alphaCompanyNameSort(companies: Company[]): Company[] {
    return _.sortBy(companies, (company) => company.name);
  }

  public get companiesFollowed(): Company[] {
    let companies = this.alphaCompanyNameSort(this.companiesDirectlyFollowed);

    if (
      this.followCompaniesImResponsibleFor &&
      this.companiesImResponsibleFor
    ) {
      companies = _.concat(
        companies,
        this.alphaCompanyNameSort(this.companiesImResponsibleFor)
      );
    }
    return companies;
  }

  public get defaultSearchName(): string {
    let count = this.user?.userSearches?.length ?? 0 + 1;
    let candidateName = this.LocPluralize(
      LocalizedTextIds.PreferencesSavedSearch,
      count,
      count
    );
    let exists = !!this.user.userSearches?.find(
      (search) => search.name === candidateName
    );
    // See if there is already a search by this name, because the user
    // might have deleted an earlier search leaving one by this name behind,
    // or they might have renamed one to this candidate name intentionally.
    // If there is a search by that name, try new candidates incrementally.
    while (exists) {
      count += 1;
      candidateName = this.LocPluralize(
        LocalizedTextIds.PreferencesSavedSearch,
        count,
        count
      );
      exists = !!this.user.userSearches?.find(
        (search) => search.name === candidateName
      );
    }
    return candidateName;
  }

  private async getCompaniesImResponsibleForIfNeeded() {
    if (
      this.followCompaniesImResponsibleFor &&
      !this.companiesImResponsibleFor
    ) {
      // Fetch list of JPAL companies
      const filter = Filter.emptyFilter;
      filter.isMyJPALCompaniesOnly = true;
      const jpalSearchResult = await this._companyService.searchAndFilter(
        '',
        0,
        0,
        filter
      );
      jpalSearchResult.companies.forEach((company) => company.isJPAL = true);
      this.companiesImResponsibleFor = jpalSearchResult.companies;
    }
  }

  private async cacheCompanies(idList: string[]) {
    await asyncForEach(idList, async (id: string) => {
      if (!this.companyCache.has(id)) {
        this.companyCache.set(id, await this._companyService.getById(id));
      }
    });
  }

  public async addCompanies(companiesToAdd: Company[]): Promise<IUser> {
    const companyIdsToAdd = companiesToAdd.map((company) => company.id);
    return this.addCompanyIds(companyIdsToAdd);
  }

  public async addCompanyIds(companyIdsToAdd: string[]): Promise<IUser> {
    this.showCompaniesPickerModal = false;

    // We will need company logos etc. for added companies, so cache them now
    await this.cacheCompanies(companyIdsToAdd);

    this.updateCompaniesDirectlyFollowed(companyIdsToAdd);

    // NOTE: This pattern is a little different than locations & sectors because the preferences.component
    //       caches companies, but the wizard flow does not.
    addCompaniesToUser(companyIdsToAdd, this.user);

    return this.saveUser();
  }

  public async deleteCompany(company: Company): Promise<IUser> {
    const id = company.id;

    let pos = this.user.companiesFollowed.findIndex(
      (userCompany) => userCompany.companyId === id
    );
    if (pos >= 0) {
      this.user.companiesFollowed.splice(pos, 1);
    }

    pos = this.companiesDirectlyFollowed.findIndex(
      (thisCompany) => thisCompany.id === id
    );
    if (pos >= 0) {
      this.companiesDirectlyFollowed.splice(pos, 1);
    }

    return this.saveUser();
  }

  /** **********
   *  Locations
   */
  public showAddLocations(): void {
    this.showLocationsPickerModal = true;
  }

  public async addLocations(
    locationsWithCountsToAdd: LocationWithCountAndCanonicalString[]
  ): Promise<IUser> {
    this.showLocationsPickerModal = false;

    addLocationsToUser(locationsWithCountsToAdd, this.user);
    this.updateLocationsFollowed();

    return this.saveUser();
  }

  public async deleteLocation(locationToDelete: string): Promise<IUser> {
    this.user.locationsFollowed = this.locationsFollowed.filter(
      (loc) => loc !== locationToDelete
    );
    this.updateLocationsFollowed();

    return this.saveUser();
  }

  /** ********
   *  Sectors
   */
  public showAddSectors(): void {
    this.showSectorsPickerModal = true;
  }

  public async addSectors(
    sectorsWithCountsToAdd: SectorWithCountAndCanonicalString[]
  ): Promise<IUser> {
    this.showSectorsPickerModal = false;

    addSectorsToUser(sectorsWithCountsToAdd, this.user);

    this.updateSectorsFollowed();

    return this.saveUser();
  }

  public async deleteSector(sectorToDelete: IUserSector): Promise<IUser> {
    const pos = this.user.sectorsFollowed.findIndex(
      (userSector) =>
        userSector.sectorId === sectorToDelete.sectorId &&
        userSector.parentSectorId === sectorToDelete.parentSectorId
    );
    if (pos >= 0) {
      this.user.sectorsFollowed.splice(pos, 1);
    }
    this.updateSectorsFollowed();

    return this.saveUser();
  }

  public sectorToDisplayString(sector: IUserSector): string {
    return sectorToDisplayString(sector);
  }

  /** ********
   * Saved Searches
   */
  public createSavedSearch(): void {
    this.savedSearchToEdit = {
      sectors: [],
      locations: [],
      statuses: '',
    } as IUserSearch;
    this.savedSearchModal.open('new');
  }

  public editSavedSearch(savedSearch: IUserSearch): void {
    this.savedSearchToEdit = _.cloneDeep(savedSearch);
    this.savedSearchModal.open('edit');
  }

  public async addSavedSearch(savedSearch: IUserSearch): Promise<IUser> {
    if (!this.user.userSearches) {
      this.user.userSearches = [];
    }

    const existingSearchIndex = this.findExistingSearchIndex(savedSearch);

    if (existingSearchIndex < 0) {
      // If the search isn't already in the user's searches, add it
      this.user.userSearches.push(savedSearch);
    } else {
      // otherwise, replace the existing one
      this.user.userSearches.splice(existingSearchIndex, 1, savedSearch);
    }
    const savedSearchSummaryComponent = this.savedSearchSummaryComponents.find(
      (comp) => comp.search.name === savedSearch.name
    );
    if (savedSearchSummaryComponent) {
      savedSearchSummaryComponent.getCountOfCompaniesForSavedSearch();
    }
    // Save the user
    this._user = await this.saveUser();
    // The template displays the searchesFollowed, so make sure to update those
    this.searchesFollowed = this._user.userSearches;
    return this._user;
  }

  public async deleteSavedSearch(savedSearch: IUserSearch): Promise<IUser> {
    if (!this.user.userSearches) {
      this.user.userSearches = [];
    }

    const existingSearchIndex = this.findExistingSearchIndex(savedSearch);

    if (existingSearchIndex > -1) {
      // Remove this search from the list
      this.user.userSearches.splice(existingSearchIndex, 1);
    }

    return this.saveUser();
  }

  public handleWizardAction(step: WizardStep): void {
    switch (step) {
      case WizardStep.Sectors:
        this.showAddCompanies();
        break;
      case WizardStep.Companies:
        this.showAddLocations();
        break;
      case WizardStep.Locations:
        this.showPreferences();
        break;
    }
  }

  // private methods
  private findExistingSearchIndex(savedSearch: IUserSearch): number {
    return _.findIndex(
      this.user.userSearches,
      (search) => search.id === savedSearch.id
    );
  }

  private async saveUser(): Promise<IUser> {
    if (!this.isUserLoaded) {
      return;
    }
    // TODO: error handling
    // TODO: Maybe make API's that take just parts of User object (like companiesFollowed) and
    // do a fetch, update just that part, and save, to avoid stepping on other concurrent changes.
    const result = await this._userService.save(this.user);

    await this.updateCountOfMatchedCompanies();

    return result;
  }

  private async updateCountOfMatchedCompanies(): Promise<void> {
    const matchedCompanyCounts = await updateCountOfMatchedCompanies(
      this._userService
    );
    this.matchedLocationsCount = matchedCompanyCounts.matchedLocationsCount;
    this.matchedSectorsCount = matchedCompanyCounts.matchedSectorsCount;
    this.matchedSearchesCount = matchedCompanyCounts.matchedSearchesCount;
    this.matchedBlueKnightCount = matchedCompanyCounts.matchedBlueKnightCount;
    this.matchedTotal = matchedCompanyCounts.matchedTotal;
  }

  private updateSectorsFollowed(): void {
    this.sectorsFollowed = _.sortBy(
      this.user.sectorsFollowed || [],
      (userSector) => userSector.sectorId
    );
  }

  private updateCompaniesDirectlyFollowed(idList: string[]): void {
    const companiesToAdd = idList.map((id) => this.companyCache.get(id));
    const followedCompanyIds = this.user.companiesFollowed.map(
      (uc) => uc.companyId
    );
    companiesToAdd.forEach((company: Company) => {
      if (!followedCompanyIds.includes(company.id)) {
        this.companiesDirectlyFollowed.push(company);
      }
    });
  }

  private updateLocationsFollowed(): void {
    this.locationsFollowed = _.sortBy(
      this.user.locationsFollowed,
      (userLocation) => userLocation
    );
  }
}
