import { TagWithCount, PublicOverrides } from 'company-finder-common';
import { RoleGroups, UserRoles } from '../model';
import { Company } from '../model/company';
import { Opportunity } from '../model/opportunity';

export enum CompanyResolutionType {
    Details = 'details',
    Update = 'update',
}

export function isNewCompany(company: Company): boolean {
    const threeMonthsAgo: Date = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    // NOTE: Javascript Dates are serialized & deserialized as strings, so rehydrate the commencement date then compare.
    return new Date(company.commencementDate) > threeMonthsAgo;
}

/**
 * Removes fields from the company object that should not be shown
 * to the current user, based on their role.
 *
 * N.B. Since there is no way for an unauthenticated user to request
 * a single company, this will *NOT* remove fields that are visible only
 * to authenticated users for an unauthenticated user.
 * @param company The company to sanitize
 */
export function stripSensitiveFields(
    company: Company,
    currentUserRole: UserRoles,
    publicFields: PublicOverrides // Even though this is in the config, adding it will create a circular reference here
): void {
    if (!company) {
        return;
    }
    // We only ever use this on the backend, no reason to return
    delete company.coOwners;

    if (!RoleGroups.InternalSuperUsers.containsRole(currentUserRole)) {
        // Remove JJI-specific fields
        //  Note that we assume all Site/Region Heads and Opportunity Co-Owners will be JJI,
        //  otherwise stripping of some data and/or authorization restrictions could present problems with Company Update.
        delete company.opportunities;
        delete company.progressCreatedDate;
        delete company.progressUpdate;
        delete company.progressSubject;
        delete company.limitedDealOpportunities;

        company.deals = company.deals?.filter((deal) => !deal.isConfidential);
        company.funding = company.funding?.filter(
            (funding) => !funding.isConfidential
        );
    }

    // Remove J&J only fields
    if (!RoleGroups.InternalUsers.containsRole(currentUserRole)) {
        delete company.siteHead;

        if (!publicFields.companyContact) {
            delete company.companyContact;
        }
        delete company.priority;
        delete company.keyMgmtAndAdvBm;
        delete company.keyDifferentiation;

        if (!publicFields.investmentSummary) {
            delete company.highestLevelOfFundingSecured;
            delete company.totalSecuredAndContingentAmount;
        }

        if (!publicFields.deals) {
            delete company.deals;
        }

        if (!publicFields.funding) {
            delete company.funding;
        }

        delete company.jpalContact;
        delete company.womenLed;
        delete company.womenLedOrgLeadership;
        delete company.womenLedBoardOfDirectorsOrEquiv;
        delete company.countryForDeiReporting;
        delete company.firstTimeEntrepreneur;
        delete company.topTierTeam;

        if (!publicFields.currentRdStage) {
            delete company.currentRdStage;
        }
    }

    if (!RoleGroups.InternalOrBardaUsers.containsRole(currentUserRole)) {
        removeBlueKnightData(company);
    }
}

export function removeBlueKnightData(company: Company): void {
    // Blue Knight fields are listed, so take advantage of that to strip them all out
    for (const key in company) {
        if (Company.isBlueKnightProperty(key)) {
            delete company[key];
        }
    }
}

export function hasDealsWithJnJ(company: Company, includeLimitedOpps = false): boolean {
    return (
        contractExecutedOpportunities(company).length > 0 ||
        // Brittany mentioned potentially modeling all "deals" as opportunity:'contract executed'
        //  and eliminating the need to include based on isQfcWinner.  Until that time, we need to look at this too.
        company.isQfcWinner ||
        (includeLimitedOpps && company.limitedDealOpportunities?.length > 0)
    );
}

export function contractExecutedOpportunities(company: Company): Opportunity[] {
    return company.opportunities?.filter(
        (opp) => opp.stage === 'Contract Executed'
    ) ?? new Array<Opportunity>();
}

export function stringIsJforceId(input: string) {
    // A J&J opportunity ID is a salesforce ID. Salesforce IDs
    // are always 18 digit identifiers and the first three characters
    // are a code representing the object type. Checking the data lake
    // verified that this is '006' across all environments for opportunity IDs
    return input.length === 18 && input.startsWith('006');
}

/**
 * Returns all tags in a decreasing frequency-based sort.
 */
export function orderByTagCount(
    company: Company,
    tagCounts: Array<TagWithCount>
): string[] {
    if (!tagCounts) {
        return [];
    }
    return company.tags
        .reduce((result, tag) => {
            const item = tagCounts.find((tagCount) => tagCount.tag === tag);
            result.push({ tag: tag, count: item ? item.count : 0 });
            return result;
        }, new Array<TagWithCount>())
        .sort((a, b) => b.count - a.count)
        .map((item) => item.tag);
}

export function urlIdentifierForCompany(companyName: string): string {
    companyName = companyName.replace(/_/g, '__');
    companyName = companyName.replace(/ /g, '_')
    return encodeURIComponent(companyName);
}

export function urlIdentifierToCompanyName(urlValue: string): string {
    let companyName = decodeURIComponent(urlValue);
    companyName = companyName.replace(/_/g, ' ');
    companyName = companyName.replace(/  /g, '_');
    return companyName;
}
