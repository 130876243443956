import { EventFilterType } from './EventFilterType';
import { HasMatches } from './HasMatches';

import _ from 'lodash';

export class MatchedCompanyInfo extends HasMatches { 
    public static matchesEventFilterType(
        matchedCompanyInfo: MatchedCompanyInfo,
        eventFilterTypes: EventFilterType[]
    ): boolean {
        return (
            (_.includes(eventFilterTypes, EventFilterType.SectorsSubSectors) &&
                matchedCompanyInfo.matchedSectors?.length > 0) ||
            (_.includes(eventFilterTypes, EventFilterType.Tags) &&
                matchedCompanyInfo.matchedTags?.length > 0) ||
            (_.includes(eventFilterTypes, EventFilterType.JLABSLocation) &&
                matchedCompanyInfo.matchedLocations?.length > 0) ||
            (_.includes(eventFilterTypes, EventFilterType.SavedSearches) &&
                matchedCompanyInfo.matchedSearches?.length > 0) ||
            (_.includes(eventFilterTypes, EventFilterType.Companies) &&
                (matchedCompanyInfo.isFollowing || matchedCompanyInfo.matchedBlueKnight) ||
            (_.includes(eventFilterTypes, EventFilterType.BlueKnight) &&
                matchedCompanyInfo.matchedBlueKnight))
        );
    }

    public constructor(
        public id: string,
        public opportunityIdPrimary: string,
        public name: string,
        public logoBase64Hash: string,
        public isFollowing: boolean,
        public matchedLocations: string[],
        public matchedSearches: string[],
        public matchedSectors: string[],
        public matchedTags: string[],
        public matchedBlueKnight: boolean = false,
        public joinedReasons?: string, // A comma-joined string of tags, sectors, locations, searches matched
        public url?: string
    ) {
        super(matchedLocations, matchedSearches, matchedSectors, matchedTags, matchedBlueKnight);
        this.id = id;
        this.opportunityIdPrimary = opportunityIdPrimary;
        this.name = name;
        this.logoBase64Hash = logoBase64Hash;
        this.isFollowing = isFollowing;
        this.joinedReasons = joinedReasons;
        this.url = url;
    }
}

export interface MatchedCompanyCounts {
    matchedLocationsCount: number;
    matchedSectorsCount: number;
    matchedSearchesCount: number;
    matchedBlueKnightCount: number;
    matchedTotal: number;
  }