import { ModelBase } from './_model.base';
import { Contact, CoOwnerContact } from './contact';
import { Opportunity } from './opportunity';
import { Location } from './location';
import { LimitedDealOpportunity } from './limitedDealOpportunity';
import _ from 'lodash';
import { Conference } from './conference';
import { Deal } from './deal';
import { Funding } from './funding';
import { nameof } from '../utility/string-util';

/**
 * @summary Note that a subset of fields are removed server-side based on a user's role, via stripSensitiveFields
 * and other fields may be removed based on feature flags.
 * @description This approach replaced a PublicCompany -> Company inheritance hierarchy that broke down when v5 introduced
 * multiple role profiles that had access to various subsets of Company properties.
 */
export class Company extends ModelBase {
    /** The date upon which a Company is considered to have engaged with JLABS.
     * The basis of a "New Company" determination, and could be a month or so in the future.
     */
    public commencementDate: Date;
    public graduationDate: Date;
    public currentRdStage: string;
    public description: string;
    public isBlueKnight: boolean;
    public isExcludeFromReporting: boolean;
    public isPublicityAgreementSigned: boolean;
    public isPubliclyOffered: boolean;
    public isQfcWinner: boolean;
    public isStealthMode: boolean;
    public locations: Location[];
    public name: string;
    public logoBase64: string;
    public logoBase64Hash: string;
    public statuses: string[];

    public opportunityIdPrimary: string;
    public opportunityLastModifiedDate: Date;
    public primaryFocus: string;
    public primarySector: string;
    public primarySubSector: string;
    public primarySectorDetail: string;
    public problem: string;
    public secondarySector: string;
    public secondaryFocus: string;
    public secondarySubSector: string;
    public secondarySectorDetail: string;
    public solution: string;
    public tags: string[];
    public totalSecuredAndContingentAmount: number;
    public wasAcquired: boolean;
    public website: string;
    public opportunityOwnerContactId: string;
    public siteHead: Contact;
    public coOwners: CoOwnerContact[];
    public ceoContactId: string;
    public companyContact: Contact;
    public womenLed: boolean;
    public minorityLed: boolean;
    public firstTimeEntrepreneur: boolean;
    public countryForDeiReporting: boolean;
    public leadershipDiversity: boolean;
    public boardAdvisorDiversity: boolean;
    public womenLedOrgLeadership: boolean;
    public womenLedBoardOfDirectorsOrEquiv: boolean;
    public topTierTeam: boolean;

    public isJPAL?: boolean;

    /** The Public version of the Company may contain a subset of Opportunities for Deal aggregation */
    public opportunities: Opportunity[];
    public limitedDealOpportunities: LimitedDealOpportunity[];

    public companyUpdateUpdatedDate: Date;
    public companyUpdateApproverEmail: string;
    public companyUpdateComments: string;

    public keyDifferentiation: string;
    public keyMgmtAndAdvBm: string;
    public priority: string;
    public progressCreatedDate: Date;
    public progressSubject: string;
    public progressUpdate: string;
    public jpalContactId: string;
    public jpalContact: Contact;
    public deals: Deal[];
    public funding: Funding[];
    public navigatorUrl: string;
    public highestLevelOfFundingSecured = 'Other';

    // BlueKnight/BARDA properties
    public blueKnightCommencementDate?: Date;
    public companyObjective?: string;
    public approachUsecase?: string;
    public entryExitStrategy?: string;
    public keyPublicationsOfScience?: string;
    public mentorship?: string;
    public engagement?: string;
    // Technology Readiness Levels
    public trl?: string;
    public twelveMonthTrl?: string;
    public twentyFourMonthTrl?: string;

    public thirtySixMonthTrl?: string;
    public fortyEightMonthTrl?: string;
    public sixtyMonthTrl?: string;

    // Future headcount needs
    public numEmpFutureNeeds?: number;
    public twelveMonthNumEmpFutureNeeds?: number;
    public twentyFourMonthNumEmpFutureNeeds?: number;
    public thirtySixMonthNumEmpFutureNeeds?: number;
    public fortyEightMonthNumEmpFutureNeeds?: number;
    public sixtyMonthNumEmpFutureNeeds?: number;
    // Future funding needs
    public currencyBlueKnight?: string;
    // Conferences
    public conferencesAttended?: Conference[];
    public anticipatedConferences?: Conference[];
    // Milestones
    // R & D Milestones
    public rdMilestones12Month: string;
    public rdMilestones24Month: string;
    public rdMilestones36Month: string;
    public rdMilestones48Month: string;
    public rdMilestones60Month: string;
    // IP Milestones
    public ipMilestones12Month: string;
    public ipMilestones24Month: string;
    public ipMilestones36Month: string;
    public ipMilestones48Month: string;
    public ipMilestones60Month: string;
    // Key Barriers & Challenges
    public barriersChallenges12Month: string;
    public barriersChallenges24Month: string;
    public barriersChallenges36Month: string;
    public barriersChallenges48Month: string;
    public barriersChallenges60Month: string;
    // R & D Partnerships
    public rdPartnerships12Month: string;
    public rdPartnerships24Month: string;
    public rdPartnerships36Month: string;
    public rdPartnerships48Month: string;
    public rdPartnerships60Month: string;
    // Funbding & Investments
    public fundingInvestments12Month: string;
    public fundingInvestments24Month: string;
    public fundingInvestments36Month: string;
    public fundingInvestments48Month: string;
    public fundingInvestments60Month: string;

    private static _blueKnightPropertyNames: string[] = [
        nameof<Company>('blueKnightCommencementDate'),
        nameof<Company>('blueKnightCommencementDate'),
        nameof<Company>('companyObjective'),
        nameof<Company>('approachUsecase'),
        nameof<Company>('entryExitStrategy'),
        nameof<Company>('keyPublicationsOfScience'),
        nameof<Company>('mentorship'),
        nameof<Company>('engagement'),
        nameof<Company>('trl'),
        nameof<Company>('twelveMonthTrl'),
        nameof<Company>('twentyFourMonthTrl'),

        nameof<Company>('thirtySixMonthTrl'),
        nameof<Company>('fortyEightMonthTrl'),
        nameof<Company>('sixtyMonthTrl'),

        nameof<Company>('numEmpFutureNeeds'),
        nameof<Company>('twelveMonthNumEmpFutureNeeds'),
        nameof<Company>('twentyFourMonthNumEmpFutureNeeds'),
        nameof<Company>('thirtySixMonthNumEmpFutureNeeds'),
        nameof<Company>('fortyEightMonthNumEmpFutureNeeds'),
        nameof<Company>('sixtyMonthNumEmpFutureNeeds'),

        nameof<Company>('currencyBlueKnight'),
        nameof<Company>('conferencesAttended'),
        nameof<Company>('anticipatedConferences'),
        nameof<Company>('rdMilestones12Month'),
        nameof<Company>('rdMilestones24Month'),
        nameof<Company>('rdMilestones36Month'),
        nameof<Company>('rdMilestones48Month'),
        nameof<Company>('rdMilestones60Month'),
        nameof<Company>('ipMilestones12Month'),
        nameof<Company>('ipMilestones24Month'),
        nameof<Company>('ipMilestones36Month'),
        nameof<Company>('ipMilestones48Month'),
        nameof<Company>('ipMilestones60Month'),
        nameof<Company>('barriersChallenges12Month'),
        nameof<Company>('barriersChallenges24Month'),
        nameof<Company>('barriersChallenges36Month'),
        nameof<Company>('barriersChallenges48Month'),
        nameof<Company>('barriersChallenges60Month'),
        nameof<Company>('rdPartnerships12Month'),
        nameof<Company>('rdPartnerships24Month'),
        nameof<Company>('rdPartnerships36Month'),
        nameof<Company>('rdPartnerships48Month'),
        nameof<Company>('rdPartnerships60Month'),
        nameof<Company>('fundingInvestments12Month'),
        nameof<Company>('fundingInvestments24Month'),
        nameof<Company>('fundingInvestments36Month'),
        nameof<Company>('fundingInvestments48Month'),
        nameof<Company>('fundingInvestments60Month'),
    ];

    public static isBlueKnightProperty(name: string): boolean {
        return _.includes(this._blueKnightPropertyNames, name);
    }
}
